import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

export default function UserForm({user, isEdit, onSubmitUser}) {
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            id: '',
            username: '',
            email: '',
            password: '',
            telefono: '',
            active: 1,
            rol_id: 1,
        }
    });
    const roles = useSelector(state => {
        const { roles } = state;
        return roles;
    });

    useEffect(() => {
        if(user) {
            reset(user);
        } else reset({
            id: '',
            username: '',
            email: '',
            password: '',
            telefono: '',
            active: 1,
            rol_id: 1,
        });
    }, [user]);

    const handleChangeRol = (e) => { setValue('rol_id', parseInt(e.target.value)); }

    const onSubmit = async (data, e) => {
        e.preventDefault();
       onSubmitUser(data);
    }

    return (
        <form className="w-100 form" onSubmit={handleSubmit(onSubmit)}>
            <input 
                type="hidden" 
                {...register("id")} 
                defaultValue={isEdit ? user.id : ''} />

            <div className="w-100 d-flex">
                <div className="w-50 d-flex flex-column mt-5 me-5">
                    <div className="d-flex align-items-center">
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                {...register("username", { required: true, maxLength: 50 })} 
                                defaultValue={isEdit ? user.username : ''} />
                            
                            <div className="form-invalid">
                                {errors.username?.type === 'required' && "Username es obligatorio"}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                {...register("email", { required: true, maxLength: 255 })} 
                                defaultValue={isEdit ? user.email : ''} />

                            <div className="form-invalid">
                                {errors.username?.type === 'required' && "Email es obligatorio"}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-50 d-flex justify-content-between flex-column mt-5">
                    { !isEdit &&
                        <div className="d-flex align-items-center">
                            <div className="form-group">
                                <label htmlFor="email">Password</label>
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    {...register("password", { required: true, maxLength: 25 })} 
                                    defaultValue={isEdit ? user.password : ''} />

                                <div className="form-invalid">
                                    {errors.username?.type === 'required' && "Password es obligatorio"}
                                </div>
                            </div>
                        </div>
                    }

                    <div className="d-flex align-items-center">
                        <div className="form-group">
                            <label htmlFor="rol_id">Rol</label>
                            { (roles && roles.length > 0) &&
                                <select 
                                    {...register("rol_id")}
                                    defaultValue={isEdit ? user.rol_id : 1}
                                    onChange={handleChangeRol}>
                                    {roles.map(op => <option key={'roles-' + op.id} value={op.id}>{op.nombre}</option>)}
                                </select>
                            }

                            <div className="form-invalid">
                                {errors.rol?.type === 'required' && "Seleccionar un rol es obligatorio"}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        <div className="form-group">
                            <label htmlFor="telefono">Telefono (opcional):</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                {...register("telefono")} 
                                defaultValue={isEdit ? user.telefono : ''} />
                        </div>
                    </div>
                </div>
            </div>

            <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
        </form>
    )
}