import { useState, useEffect } from "react";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Header from '../shared/components/Header'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from "react-redux";
import AvisoStockModal from "./components/AvisoStockModal";
import { fetchAvisosStock } from "../../../../redux/avisos_stock";
import moment from "moment";
import { newAvisoStock, updateAvisoStock } from "../shared/services/avisosStock";
import { toast } from "react-toastify";
import { getActions } from "./actions";
import ActionsMenu from "../shared/components/ActionsMenu";

export default function Stock() {
    const dispatch = useDispatch();
    const { page } = useParams();
    const currPage = parseInt(page);
    let navigate = useNavigate();
    const [currentPage, setPage] = useState(currPage);
    const [openForm, setOpenForm] = useState(false);

    const searcher = useSelector(state => {
        return state.search
    })
    const avisosStock = useSelector(state => {
        return state.avisosStock.entities
    });
    const avisosStockStatus = useSelector(state => {
        return state.avisosStock.status
    });

    useEffect(() => {
        dispatch(fetchAvisosStock(currPage, null));
    }, [searcher]);

    const handleSearch = (search, searchData) => {
        setPage(1);
        navigate(`/avisos-stock/${1}`);
        dispatch({type: "search/set", payload: searchData});     
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        navigate(`/avisos-stock/${newPage}`);
        var query = (searcher && searcher?.search && searcher?.search.length > 0 ? 'search=' + searcher?.search : null);
        dispatch(fetchAvisosStock(newPage, query));
    }

    const getPaginationCount = () => {
        var totalPages = avisosStock.total_items < 15 ? 1 : Math.ceil(avisosStock.total_items / 15);
        return parseInt(totalPages);
    }

    const handleAction = (action, aviso) => {
        if(action === 'change') {
            handleToggle(aviso)
        }
    }

    const handleNewAviso = () => { setOpenForm(true); }
    const handleCloseForm = () => { setOpenForm(false); }
    const handleSubmitAvisoStock = async(data) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await newAvisoStock(data);

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            setOpenForm(false);
            toast.success(response.message);
            dispatch(fetchAvisosStock(currentPage, null));
        }
    }

    const handleToggle = async(aviso) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await updateAvisoStock({id: aviso.id, enable: !aviso?.avisado });

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            dispatch(fetchAvisosStock(currentPage, null));
        }
    }

    if(avisosStockStatus.loading === 'pending' || avisosStockStatus.loading === 'idle') {
        return (
        <div className="content">
            <div className="w-100 h-100 d-flex align-items-center justify-content-center loading">
                <FontAwesomeIcon icon={faSpinner} size="2x" spin /> <span>Cargando</span>
            </div>
        </div>
        )
    }

    if(avisosStockStatus.loading === 'succeed' || avisosStockStatus.loading === 'rejected') {
        const pageCount = getPaginationCount();

        return (
        <div className="content pt-3">
            <Header 
                viewSearch={true}
                onNew={handleNewAviso} 
                onSearch={handleSearch} />

            <div className="w-100 d-flex flex-column align-items-end content-table">
            <div className="table-responsive">
                <table className="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>cliente</th>
                        <th className="text-center">telefono</th>
                        <th className="text-center">fecha</th>
                        <th className="text-center">referencia</th>
                        <th className="text-center">referencia 2</th>
                        <th className="text-center">tipo</th>
                        <th className="text-center">fecha inicio aviso</th>
                        <th className="text-center">fecha fin aviso</th>
                        <th className="text-center">infinito</th>
                        <th className="text-center">resp</th>
                        <th className="text-center"></th>
                    </tr>
                </thead>

                <tbody>
                    { avisosStock.avisosStock?.map((aviso) => {
                    return (
                        <tr key={aviso.id}>
                            <td className="text-center">
                                <span title={(aviso?.infinito == 1 && aviso?.avisado == 1) ? 'Deshabilitado' : aviso.avisado == 0 ? 'En espera' : 'Avisado'} className="d-block circle mt-1 me-0" style={{backgroundColor: (aviso?.infinito == 1 && aviso?.avisado == 1) ? '#dc3545' : aviso.avisado == 0 ? '#ffc107' : '#00986C'}}></span>
                            </td>
                            <td className="text-uppercase">
                                <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + aviso.entity.id} className='text-green' title="Ficha Cliente">
                                    {aviso.entity?.nombre}
                                </Link>
                            </td>
                            <td className="text-center">{aviso.entity?.telefono}</td>
                            <td className="text-center">{moment(aviso.fecha).format('DD-MM-YYYY')}</td>
                            <td className="text-center">{aviso.referencia}</td>
                            <td className="text-center">{aviso.referencia2}</td>
                            <td className="text-center">{aviso.tipo_pieza_id ? aviso?.tipo_pieza?.nombre : '-'}</td>
                            <td className="text-center">{aviso.fecha_inicio ? moment(aviso.fecha_inicio).format('DD-MM-YYYY') : '-'}</td>
                            <td className="text-center">{aviso.fecha_fin ? moment(aviso.fecha_fin).format('DD-MM-YYYY') : '-'}</td>
                            <td className={"text-center fw-bold " + (aviso.infinito ? 'text-green' : 'text-danger')}>{aviso.infinito ? 'SI' : 'NO'}</td>
                            <td className="text-center text-uppercase">{aviso.created_by?.username}</td>
                            <td className="text-center">
                                <div className="actions">
                                    { aviso.infinito ?
                                        <ActionsMenu 
                                            options={getActions(aviso)}
                                            onAction={(action) => handleAction(action, aviso)}
                                        />
                                        : null
                                    }
                                </div>
                            </td>
                        </tr>
                    );
                    })}
                </tbody>
                </table>
            </div>

            <Pagination 
                className="mt-3" 
                count={pageCount} 
                page={currentPage} 
                onChange={handleChangePage} 
            />
            </div>

            { (openForm) &&
                <AvisoStockModal 
                    open={openForm}
                    onClose={handleCloseForm}
                    onSubmit={handleSubmitAvisoStock}
                />
            }
        </div>
        )
    }
}