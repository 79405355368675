import { useState, useEffect } from "react";
import React from "react";
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Header from '../shared/components/Header'
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import Loading from "../shared/components/Loading";
import ActionsMenu from "../shared/components/ActionsMenu";
import { getActions } from "./actions";
import { fetchNotasCargo } from "../../../../redux/notasCargo";
import ConfirmModal from "../shared/components/ConfirmModal";
import { deleteNota, generateNotaCargoPDF } from "../shared/services/nota_cargo";
import { setErrorMessage } from "../shared/helpers/functionalities";

export default function NotasCargo() {
  const dispatch = useDispatch();
  const { page } = useParams();
  const currPage = parseInt(page);
  let navigate = useNavigate();
  const [currentPage, setPage] = useState(currPage);
  const [currentNota, setCurrentNota] = useState(null);
  const [isForDelete, setIsForDelete] = useState(false);
  const notas = useSelector(state => {
    return state.notas_cargo.entities
  });
  const status = useSelector(state => {
    return state.notas_cargo.status
  });
  const searcher = useSelector(state => {
    return state.search
  })

  useEffect(() => {
    dispatch(fetchNotasCargo(currPage, getQuery()));
  }, [searcher]);

  const getQuery = () => {
    const isEmpty = Object.values(searcher).every(x => x === null || x === '');
    let query = !isEmpty ? 'search=' + (searcher?.search ? searcher?.search : '')
    : null;

    return query;
  }

  const handleSearch = (search, searchData) => {
    setPage(1);
    navigate(`/notas-cargo/${1}`);
    dispatch({type: "search/set", payload: searchData});     
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigate(`/notas-cargo/${newPage}`);
    dispatch(fetchNotasCargo(newPage, getQuery()));
  }

  const getPaginationCount = () => {
    var totalPages = notas.total_items < 15 ? 1 : Math.ceil(notas.total_items / 15);
    return parseInt(totalPages);
  }

  const handleAction = (action, nota) => {
    if(action === 'download') {
      generateThePdf(nota)
    } else  if(action === 'remove') {
      handleDelete(nota)
    } 
  }

  const generateThePdf = async (nota) => {
      dispatch({type: 'loading/set', payload: true});
      const pdfResult = await generateNotaCargoPDF(nota.id);

      if(pdfResult) {
        dispatch({type: 'loading/set', payload: false});
        toast.success('PDF descargado correctamente!')
        const url = window.URL.createObjectURL(new Blob([pdfResult]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nota.numero + '.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        toast.error('Ups! Algo ha ido mal...')
      }
  }

  const handleView = (item) => { 
    localStorage.removeItem('searcher');

    if(item === 'albaranes') {
      navigate('/albaranes/1', { replace: true });
    } else {
      navigate('/facturas/1', { replace: true });
    }
  }

  const handleDelete = (nota) => { 
    setCurrentNota(nota);
    setIsForDelete(true); 
  }

  const handleConfirmDelete = (confirm) => {
    if(confirm) {
        handleDeleteElement()
    }

    setIsForDelete(false);
  }

  const handleDeleteElement = async() => {
    dispatch({type: 'loading/set', payload: true});
    const response = await deleteNota(currentNota.id)
    .catch(function (error) {
      toast.error(setErrorMessage(error))
      dispatch({type: 'loading/set', payload: false});
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message)
      dispatch(fetchNotasCargo(currPage, getQuery()));
    }
  }

  const handleGoToEnvio = (envio) => {
    dispatch({type: "search/set", payload: {search: envio.codigo_transporte }});
    navigate('/envios/1', { replace : true});
  }

  if(status.loading === 'pending' || status.loading === 'idle') {
    return ( <Loading /> );
  }

  if(status.loading === 'succeed' || status.loading === 'rejected') {
    const pageCount = getPaginationCount();

    return (
      <div className="content pt-3">
        <Header viewSearch={true} onSearch={handleSearch}></Header>

        <div className="w-100 d-flex flex-column align-items-end content-table">
          <div className="d-flex align-items-center mb-3">
            <button className="btn btn-default me-3" onClick={() => handleView('facturas')}>Facturas</button>
            <button className="btn btn-default me-3" onClick={() => handleView('albaranes')}>Albaranes</button>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Nº</th>
                  <th className="text-center">fecha</th>
                  <th>cliente</th>
                  <th className="text-center">venta</th>
                  <th>pieza</th>
                  <th className="text-center">precio compra</th>
                  <th className="text-center">total</th>
                  <th className="text-center">I.V.A</th>
                  <th className="text-center">total I.V.A</th>
                  <th className="text-center">RESP</th>
                  <th className="text-center"></th>
                </tr>
              </thead>

              { notas.notas?.map((nota, index) => {
                  const piezasValues = Object.values(nota.piezas)
                  const piezasRows = piezasValues.map((pieza, i) => {
                    const envioPieza = nota.venta?.envios?.length > 0 ? nota.venta?.envios?.find(e => e?.piezas.find(p => p.id === pieza.id)) : null;

                    const numero = i === 0 ? <td rowSpan={piezasValues.length + 1} width="5%">
                      {nota?.numero}
                    </td> : null
                    const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">
                      {moment(nota.fecha).format("DD-MM-YYYY")}
                    </td> : null
                    const cliente = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%">
                      <div className="d-flex flex-column">
                        <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + nota.venta.cliente?.id} title="Ficha Cliente" className="text-green text-uppercase">
                          {nota.venta?.cliente?.nombre}
                        </Link>
                        {nota.venta.cliente.telefono}
                      </div>
                    </td> : null
                    const venta = i === 0 ? <td rowSpan={piezasValues.length + 1} className="text-center" width="8%">
                      {nota.venta?.numero}
                    </td> : null
                    const total = i === 0 ? <td rowSpan={piezasValues.length + 1} className="text-center">
                      {nota.total}€
                    </td> : null
                    const IVA = i === 0 ? <td rowSpan={piezasValues.length + 1} className="text-center">
                      {nota.iva}%
                    </td> : null
                    const totalIva = i === 0 ? <td rowSpan={piezasValues.length + 1} width="6%" className="text-center">{parseFloat(nota.total_iva)}</td> : null
                
                   const responsable = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center text-uppercase"><small>{nota.created_by?.username}</small></td> : null
                    const acciones = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center" width={'5%'}>
                      <ActionsMenu 
                          options={getActions(nota)}
                          onAction={(action) => handleAction(action, nota)}
                      />
                    </td> : null
                        
                    return (
                      <tr key={i}>
                        {numero}
                        {fecha}
                        {cliente}
                        {venta}
                        <td>
                          <div className="w-100 d-flex flex-column text-uppercase">
                            <Link target="_blank" rel="noopener noreferrer" to={"/proveedor/ficha/" + pieza.proveedor?.id} title="Ficha Proveedor" className="text-green text-uppercase">
                              {pieza.proveedor.nombre}
                            </Link>
                            
                            <small className="w-100 d-flex flex-column">
                              <span>
                                {pieza?.venta_relationship?.unidades > 1 ? 
                                  <strong>{'x'+pieza?.venta_relationship?.unidades}</strong> : '' 
                                } 
                                {pieza.nombre}
                              </span>
                              <span>REF: <b>{pieza.referencia_pieza}</b></span>
                              { pieza?.casco > 0 ?
                                <span className="fw-bold text-uppercase highlight"><small>contiene casco</small></span>
                                : ''
                              }
                            </small>

                            {
                              envioPieza ?
                              <div className="d-flex flex-column mt-1">
                              { envioPieza.numero_seguimiento !== '' ?
                                  <>
                                    <span onClick={() => handleGoToEnvio(envioPieza)} className="text-green link">{envioPieza.codigo_transporte}</span>
                                    <small>{envioPieza.transportista_id === 1 && envioPieza.numero_seguimiento === '-' ? 'PROVEEDOR' : envioPieza.numero_seguimiento}</small>
                                  </>
                                : '-'
                              }
                              </div>
                              : null
                            }
                          </div>
                        </td>
                        <td className="text-center" width={'5%'}>{parseFloat(pieza.precio_compra).toFixed(2)}€</td>
                        {total}
                        {IVA}
                        {totalIva}
                        {responsable}
                        {acciones}
                      </tr>
                    )
                  })
                  return (
                    <tbody key={index}>
                      {piezasRows}
                    </tbody>
                  )
                }) 
              }
            </table>
          </div>

          <Pagination 
            className="mt-3" 
            count={pageCount} 
            page={currentPage} 
            onChange={handleChangePage} 
          />
        </div>

        { isForDelete && 
            <ConfirmModal 
                onConfirmAction={handleConfirmDelete} 
                title={'Eliminar'} 
                description={'Estás seguro de eliminar?'}
                state={isForDelete}>
            </ConfirmModal>
        }
      </div>
    )
  }
}