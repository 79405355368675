import Loading from "../../../../../../../../../../../shared/components/Loading";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { scrappingBlatReferencia } from "../../../../../../../../../../../shared/services/scrapping";
import { scrappingBlatURL } from "../../../../../../../../../../../shared/services/scrapping";
import { setErrorMessage } from "../../../../../../../../../../../shared/helpers/functionalities";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSearch } from "@fortawesome/free-solid-svg-icons";

export default function ScrappingBlatForm({ onScrapping }) {
  /** Variables */
  const rrrListId = "rrr-lt-0c56b687";
  const gpaId = "gpa26-com-24a0d8b8";
  const {
    register,
    formState: { errors },
    handleSubmit: handleSubmitURL,
  } = useForm();
  const [searchType, setSearchType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dots, setDots] = useState([".", ".", "."]);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [dataToLoad, setDataToLoad] = useState([
    {
      type: "Ovoko",
      data: null,
      completed: false,
    },
    {
      type: "Azelerecambios",
      data: null,
      completed: false,
    },
    {
      type: "Opisto",
      data: null,
      completed: false,
    },
    {
      type: "GPA26",
      data: null,
      completed: false,
    },
  ]);

  /** Lifecycle **/
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
    }, 250);
    return () => clearInterval(interval);
  }, []);

  /** Methods **/
  const onSubmit = async (data, event) => {
    event.preventDefault();

    if (searchType !== "todos") {
      let singleResponse = null;

      if (searchType === "gpa") {
        const gpaScrapperReference = await scrapByReferenceResponse(
          "gpa",
          data.referencia,
          true
        );

        singleResponse = await scrapByUrlResponse(
          gpaScrapperReference.data[0].url,
          gpaId
        );
      } else {
        const getDataTypePage = (type) => {
          switch (type) {
            case "rrr":
              return data.rrr_page;
            case "azelerecambios":
              return data.azelerecambios_page;
            case "opisto":
              return data.opisto_page;
            default:
              return 1;
          }
        };

        singleResponse = await scrapByReferenceResponse(
          searchType,
          data.referencia,
          true,
          getDataTypePage(searchType),
          searchType === "rrr" ? rrrListId : null
        );
      }

      let scrappingData = {
        list: { [searchType]: singleResponse.data },
        oem_code: data.referencia,
      };
      onScrapping(scrappingData, data.referencia);
      return;
    }

    const rrrScrapperReference = await scrapByReferenceResponse(
      "rrr",
      data.referencia,
      false,
      data.rrr_page,
      rrrListId
    );
    onLoaded("Ovoko", rrrScrapperReference);
    const azelerecambiosResponse = await scrapByReferenceResponse(
      "azelerecambios",
      data.referencia,
      false,
      data.azelerecambios_page
    );
    onLoaded("Azelerecambios", azelerecambiosResponse);
    const opistoResponse = await scrapByReferenceResponse(
      "opisto",
      data.referencia,
      false,
      data.opisto_page
    );
    onLoaded("Opisto", opistoResponse);
    const gpaScrapperReference = await scrapByReferenceResponse(
      "gpa",
      data.referencia,
      true
    );
    const gpaResponse = gpaScrapperReference.data[0]?.url
      ? await scrapByUrlResponse(gpaScrapperReference.data[0]?.url, gpaId)
      : null;
    onLoaded("Gpa26", gpaResponse);

    const scrappingListResponse = {
      data: {
        rrr: rrrScrapperReference?.data,
        azelerecambios: azelerecambiosResponse?.data,
        opisto: opistoResponse?.data,
        gpa: gpaResponse?.data,
      },
    };

    if (
      rrrScrapperReference ||
      azelerecambiosResponse ||
      opistoResponse ||
      gpaResponse
    ) {
      scrappingListResponse.success = true;

      if (scrappingListResponse && scrappingListResponse.success) {
        setIsLoading(false);
        const scrappingData = {
          list: scrappingListResponse.data,
          oem_code: data.referencia,
        };
        onScrapping(scrappingData);
      }
    } else {
      toast.error(
        setErrorMessage(
          "ERROR! Ha ocurrido un problema o el anuncio no está disponible."
        )
      );
      return;
    }
  };

  const scrapByReferenceResponse = async (
    type,
    reference,
    withError,
    page,
    id
  ) => {
    setIsLoading(true);
    return await scrappingBlatReferencia(type, reference, page, id)
      .catch(() => {
        if (withError) {
          toast.error(
            setErrorMessage({
              message:
                "ERROR! Ha ocurrido un problema o no hay resultados para la referencia.",
            })
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const scrapByUrlResponse = async (url, id) => {
    setIsLoading(true);
    return await scrappingBlatURL(url, id)
      .catch((error) => {
        toast.error(
          setErrorMessage(
            error
              ? error
              : "ERROR! Ha ocurrido un problema o el anuncio no está disponible."
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onLoaded = (type, response) => {
    setLoadingProgress((prevProgress) => prevProgress + 25);
    setDataToLoad((prevData) =>
      prevData.map((data) => {
        if (data.type === type) {
          return { ...data, data: response?.data || [], completed: true };
        }
        return data;
      })
    );
  };

  const getInputStyle = () => {
    return {
      width: "48px",
      border: "1px solid #215731",
      padding: "10px",
      borderRadius: "5px",
      fontWeight: "lighter",
      fontSize: "14px",
      textAlign: "center",
      paddingLeft: "5px",
    };
  };

  const getButtonStyle = () => {
    return {
      whiteSpace: "nowrap",
      background: "#215731",
      padding: "10px",
      color: "#fff",
      borderRadius: "5px",
      fontWeight: "bold",
      width: "auto",
      minHeight: "43px",
      width: "100%",
    };
  };

  return (
    <>
      {isLoading && searchType === "todos" && (
        <div className="d-flex align-items-center flex-column">
          <div className="d-flex">
            <span>Loading</span>
            <span style={{ width: "20px" }}>{dots}</span>
          </div>
          <Box sx={{ width: "50%" }}>
            <LinearProgressWithLabel value={loadingProgress} />
          </Box>
          {dataToLoad.map((data, index) => (
            <div className="d-flex gap-2" key={index}>
              {data.completed && (
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1x"
                  className="action"
                  color="#2EA745"
                />
              )}
              <strong style={{ color: data.completed ? "black" : "gray" }}>
                {data.type}
              </strong>
              <span style={{ color: data.completed ? "black" : "gray" }}>
                ({data.data?.length || 0} piezas encontradas)
              </span>
            </div>
          ))}
        </div>
      )}

      {isLoading && searchType !== "todos" && <Loading />}

      {!isLoading && (
        <>
          <h5 className="fw-bold mb-3">Buscador de referencias</h5>
          <form
            className="d-flex flex-column"
            onSubmit={handleSubmitURL(onSubmit)}
          >
            <div className="row g-2">
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Referencia pieza (OEM)"
                  {...register("referencia", { required: true })}
                  defaultValue=""
                />
                <div className="form-invalid">
                  {errors.referencia?.type === "required" &&
                    "La referencia de la pieza es obligatoria"}
                </div>
              </div>
              <div className="col-4">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="btn btn-success w-100"
                  style={{
                    whiteSpace: "nowrap",
                    backgroundColor: "#E9EEEA",
                    color: "#215731",
                    minHeight: "43px",
                  }}
                  onClick={() => setSearchType("todos")}
                >
                  <div className="d-flex gap-2 justify-content-center">
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="1x"
                      className="action"
                      color="#215731"
                    />
                    <span>Todos</span>
                  </div>
                </button>
              </div>
            </div>

            <div className="d-flex flex-column w-100">
              <div className="d-flex flex-column flex-lg-row pb-2 pt-2 gap-2">
                <div className="d-flex gap-1 align-items-center w-100">
                  <span className="d-lg-none d-xl-inline">Página:</span>
                  <input
                    {...register("rrr_page", { required: true })}
                    defaultValue="1"
                    style={getInputStyle()}
                    placeholder="Pag."
                  />
                  <button
                    type="submit"
                    disabled={isLoading}
                    style={getButtonStyle()}
                    className="px-md-4"
                    onClick={() => setSearchType("rrr")}
                  >
                    <div className="d-flex gap-2 justify-content-center">
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="1x"
                        className="action"
                        color="#fff"
                      />
                      <span>Ovoko</span>
                    </div>
                  </button>
                </div>
                <div className="d-flex gap-1 align-items-center w-100">
                  <span className="d-lg-none d-xl-inline">Página:</span>
                  <input
                    {...register("azelerecambios_page", { required: true })}
                    defaultValue="1"
                    style={getInputStyle()}
                    placeholder="Pag."
                  />
                  <button
                    type="submit"
                    disabled={isLoading}
                    style={getButtonStyle()}
                    className="px-md-4"
                    onClick={() => setSearchType("azelerecambios")}
                  >
                    <div className="d-flex gap-2 justify-content-center">
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="1x"
                        className="action"
                        color="#fff"
                      />
                      <span>Azelerecambios</span>
                    </div>
                  </button>
                </div>
                <div className="d-flex gap-1 align-items-center w-100">
                  <span className="d-lg-none d-xl-inline">Página:</span>
                  <input
                    {...register("opisto_page", { required: true })}
                    defaultValue="1"
                    style={getInputStyle()}
                    placeholder="Pag."
                  />
                  <button
                    type="submit"
                    disabled={isLoading}
                    style={getButtonStyle()}
                    className="px-md-4"
                    onClick={() => setSearchType("opisto")}
                  >
                    <div className="d-flex gap-2 justify-content-center">
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="1x"
                        className="action"
                        color="#fff"
                      />
                      <span>Opisto</span>
                    </div>
                  </button>
                </div>
                <div className="d-flex gap-1 align-items-center w-100">
                  <button
                    type="submit"
                    disabled={isLoading}
                    style={getButtonStyle()}
                    className="px-md-4"
                    onClick={() => setSearchType("gpa")}
                  >
                    <div className="d-flex gap-2 justify-content-center">
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="1x"
                        className="action"
                        color="#fff"
                      />
                      <span>GPA26</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
}

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" color="success" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
