import API from '../../../../../services/api';

export const getNotasCargo = async () => {
    return await API.get('/nota-cargo').then(res => res.data);
}

export const newNota = async (nota) => {
    return API.post('/nota-cargo', nota).then(res => res.data);
}

export const generateNotaCargoPDF = async (idNota) => {
    return API.get('/nota-cargo/generate_pdf/' + idNota, {responseType: 'blob'}).then(res => res.data);
}

export const deleteNota = async (idNota) => {
    return API.delete('/nota-cargo/' + idNota).then(res => res.data);
}