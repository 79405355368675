import { useState, useEffect } from "react";
import React from "react";
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Header from '../shared/components/Header'
import { faEye, faLink, faTag, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from "react-redux";
import { fetchEnvios } from "../../../../redux/envios";
import moment from "moment";
import InfoModal from "../shared/components/InfoModal";
import EnvioForm from "./components/EnvioForm";
import { cancelarEnvio, consumoEnviosExport, getEtiqueta, incidenciaEnvio, newEnvio, regenerarEnvioPendiente, updateEnvio } from "../shared/services/envios";
import EnvioDetail from "./components/EnvioDetail";
import MessageForm from "./components/MessageForm";
import ExportForm from "../shared/components/ExportForm";
import Loading from "../shared/components/Loading";
import SeguimientoForm from "./components/SeguimientoForm";
import { getEstado, setErrorMessage } from "../shared/helpers/functionalities";
import ConfirmModal from "../shared/components/ConfirmModal";
import EnvioStatusDetail from "./components/EnvioStatusDetail";
import { getActions } from "./actions";
import ActionsMenu from "../shared/components/ActionsMenu";

export default function Envios() {
  const dispatch = useDispatch();
  const { page } = useParams();
  const currPage = parseInt(page);
  let navigate = useNavigate();
  const [currentPage, setPage] = useState(currPage);
  const [currentEnvio, setCurrentEnvio] = useState(null);
  const [openNewEnvio, setOpenNewEnvio] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [openCancelar, setOpenCancelar] = useState(false);
  const [openIncidencia, setOpenIncidencia] = useState(false);
  const [openSeguimientoEnvio, setOpenSeguimientoEnvio] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [openRegenerate, setOpenRegenerate] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);

  const envios = useSelector(state => {
    return state.envios.entities
  });
  const enviosStatus = useSelector(state => {
    return state.envios.status
  });
  const transportistas = useSelector(state => {
      return state.transportistas
  });
  const serviciosEnvio = useSelector(state => {
      const { servicios_envio } = state;
      return servicios_envio;
  });
  const tipoPiezas = useSelector(state => {
    const { tipo_piezas } = state;
    return tipo_piezas;
  });
  const searcher = useSelector(state => {
    return state.search
  })

  useEffect(() => {
    dispatch(fetchEnvios(currPage, getQuery()));
  }, [searcher]);

  const getQuery = () => {
    const isEmpty = Object.values(searcher).every(x => x === null || x === '');
    const query = !isEmpty ? 'search=' + (searcher?.search ? searcher?.search : '') 
      + (searcher?.start_date ? '&start_date=' + searcher?.start_date : '')
      + (searcher?.end_date ? '&end_date=' + searcher?.end_date : '')
      + (searcher?.responsable ? '&responsable=' + searcher?.responsable : '')
      + (searcher?.transportista ? '&transportista=' + searcher?.transportista : '')
    : null;

    return query;
  }

  const handleSearch = (search, searchData) => {
    setPage(1);
    navigate(`/envios/${1}`);
    dispatch({type: "search/set", payload: searchData});     
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigate(`/envios/${newPage}`);
    dispatch(fetchEnvios(newPage, getQuery()));
  }

  const getPaginationCount = () => {
    var totalPages = envios.total_items < 15 ? 1 : Math.ceil(envios.total_items / 15);
    return parseInt(totalPages);
  }

  const handleAction = (action, envio) => {
    if(action === 'details') {
      handleOpenInfo(envio)
    } else  if(action === 'download') {
      generateThePdf(envio)
    } else  if(action === 'regenerate') {
      handleOpenRegenerate(envio)
    } else if(action === 'cancel') {
      handleCancelar(envio)
    } else if(action === 'add-info') {
      handleOpenAddSeguimiento(envio)
    }
}

  const getTransportista = (transportistaId) => { return transportistas.find(t => t.id === transportistaId) }
  const getServicio = (servicioId) => { return serviciosEnvio.find(s => s.id === servicioId) }
  const getTipoPieza = (tipoPiezaId) => { return tipoPiezas.find(tp => tp.id  === tipoPiezaId) }

  const handleOpenNewEnvio = () => { setOpenNewEnvio(true); }
  const handleCloseNewEnvio = () => { setOpenNewEnvio(false); }
  const handleOpenInfo = (envio) => { setOpenInfo(true); setCurrentEnvio(envio); }
  const handleCloseOpenInfo = () => { setOpenInfo(false); setCurrentEnvio(null); }
  const handleCancelar = (envio) => { setOpenCancelar(true); setCurrentEnvio(envio); }
  const handleCloseCancelar = () => { setOpenCancelar(false); setCurrentEnvio(null);}
  const handleIncidencia = (envio) => { setOpenIncidencia(true); setCurrentEnvio(envio); }
  const handleCloseIncidencia = () => { setOpenIncidencia(false); setCurrentEnvio(null);}
  const handleOpenAddSeguimiento = (envio) => { setOpenSeguimientoEnvio(true); setCurrentEnvio(envio); }
  const handleCloseAddSeguimiento = () => { setOpenSeguimientoEnvio(false); setCurrentEnvio(null); }
  const handleOpenRegenerate = (envio) => { setOpenRegenerate(true); setCurrentEnvio(envio); }
  const handleConfirmRegenerate = async(confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      const response = await regenerarEnvioPendiente({envio_id: currentEnvio.id})
      .catch(function (error) {
        toast.error(setErrorMessage(error.message))
        dispatch({type: 'loading/set', payload: false});
      });

      if(response.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(response.message);
      }
    }

    setOpenRegenerate(false);
    setCurrentEnvio(null);
  }

  const handleNewEnvio = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await newEnvio(data);

    if(response.success) {
      dispatch({type: 'loading/set', payload: false});
      setOpenNewEnvio(false);
      setCurrentEnvio(null);
      toast.success(response.message);
      dispatch(fetchEnvios(currPage, null));
    }
  }

  const handleAddSeguimientoEnvio = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await updateEnvio(data);

    if(response.success) {
      dispatch({type: 'loading/set', payload: false});
      setOpenSeguimientoEnvio(false);
      setCurrentEnvio(null);
      toast.success(response.message);
      dispatch(fetchEnvios(currPage, null));
    }
  }

  const handleCancelarEnvio = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await cancelarEnvio(data);

    if(response.success) {
      dispatch({type: 'loading/set', payload: false});
      setOpenCancelar(false);
      setCurrentEnvio(null);
      toast.success(response.message);
    }
  }

  const handleIncidenciaEnvio = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await incidenciaEnvio(data);

    if(response.success) {
      dispatch({type: 'loading/set', payload: false});
      setOpenIncidencia(false);
      setCurrentEnvio(null);
      toast.success(response.message);
    }
  }

  const generateThePdf = async(envio) => {
    dispatch({type: 'loading/set', payload: true});
    let etiquetaResult =  await getEtiqueta(envio.id);
    let pdfName = envio.codigo_transporte;

    if(etiquetaResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('Etiqueta generada correctamente!')
      const url = window.URL.createObjectURL(new Blob([etiquetaResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', pdfName + '.pdf');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleGoTo = (item, search) => {
    dispatch({type: "search/set", payload: {search: search }});

    if(item === 'presupuesto') {
      navigate('/presupuestos/1', { replace : true});
    } else if(item === 'incidencia'){
      navigate('/incidencias/1', { replace : true});
    }
  }

  const handleExport = () => { setOpenExport(true); }
  const handleCloseExport = () => { setOpenExport(false); }
  const handleExportConsumo = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const exportResult = await consumoEnviosExport(data);

    if(exportResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('Listado de consumo de envíos descargado correctamente!')
    
      const url = window.URL.createObjectURL(new Blob([exportResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'envios_consumo.xlsx');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }

    setOpenExport(false);
  }

  const handleOpenStatus = (envio) => {
    setCurrentEnvio(envio);
    setOpenStatus(true);
  }
  const handleCloseStatus = () => {
    setCurrentEnvio(null);
    setOpenStatus(false);
  }

  if(enviosStatus.loading === 'pending' || enviosStatus.loading === 'idle') {
    return ( <Loading /> );
  }

  if(enviosStatus.loading === 'succeed' || enviosStatus.loading === 'rejected') {
    const pageCount = getPaginationCount();

    return (
      <div className="content pt-3">
        <Header 
          viewSearch={true}
          onExport={handleExport}
          onSearch={handleSearch}
          onNew={handleOpenNewEnvio}
        ></Header>

        <div className="w-100 d-flex flex-column align-items-end content-table">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th className="text-center">fecha</th>
                  <th>remitente</th>
                  <th>destinatario</th>
                  <th>pieza</th>
                  <th className="text-center">seguro</th>
                  <th className="text-center">asegurado</th>
                  <th className="text-center">transportista</th>
                  <th className="text-center">servicio</th>
                  <th className="text-center">total</th>
                  <th className="text-center">estado</th>
                  <th className="text-center">resp</th>
                  <th className="text-center"></th>
                </tr>
              </thead>

              { envios.envios?.map((envio, index) => {
                  let withoutPiezaRow, piezasRows = null;

                  if(envio.piezas.length > 0) {
                    const piezasValues = Object.values(envio.piezas)
                    piezasRows = piezasValues.map((pieza, i) => {
                      const codigo = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="6%">{envio.codigo_transporte}</td> : null
                      const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">
                        {moment(envio.fecha).format("DD-MM-YYYY HH:mm")}
                      </td> : null
                      const remitente = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="15%">
                        { (envio.remitente?.proveedor_id !== null) &&
                            <Link target="_blank" rel="noopener noreferrer" to={"/proveedor/ficha/" + envio.remitente?.proveedor_id} title="Ficha Proveedor" className="text-green text-uppercase">
                              {envio.remitente?.nombre}
                            </Link>
                        }
                        { (envio.remitente?.entity_id !== null) &&
                            <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + envio.remitente?.entity_id} title="Ficha Cliente" className="text-green text-uppercase">
                              {envio.remitente?.nombre}
                            </Link>
                        }
                        { (envio.remitente?.proveedor_id === null && envio.remitente?.entity_id === null) &&
                            <span className="text-uppercase">{envio.remitente?.nombre}</span>
                        }
                        </td> : null

                        const destinatario = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="15%">
                        { (envio.destinatario?.proveedor_id !== null) &&
                            <Link target="_blank" rel="noopener noreferrer" to={"/proveedor/ficha/" + envio.destinatario?.proveedor_id} title="Ficha Proveedor" className="text-green text-uppercase">
                              {envio.destinatario?.nombre}
                            </Link>
                        }
                        { (envio.destinatario?.entity_id !== null) &&
                            <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + envio.destinatario?.entity_id} title="Ficha Cliente" className="text-green text-uppercase">
                              {envio.destinatario?.nombre}
                            </Link>
                        }
                        { (envio.destinatario?.proveedor_id === null && envio.destinatario?.entity_id === null) &&
                            <span className="text-uppercase">{envio.destinatario?.nombre}</span>
                        }
                        </td> : null
                      const seguro = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center">
                        <span className={"fw-bold " + (envio.seguro ? 'text-green' : 'text-danger')}>
                            {envio.seguro ? 'SI' : 'NO'}
                        </span>
                      </td> : null
                      const asegurado = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center">
                        {envio.valor_asegurado ? envio.valor_asegurado + '€' : '-'}
                      </td> : null
                      const transportista = i === 0 ?  
                        <td rowSpan={piezasValues.length + 1}  className="text-center">
                          {getTransportista(envio.transportista_id)?.nombre}
                          { envio.venta_id &&
                            <p className="m-0" onClick={() => handleGoTo('presupuesto', envio.presupuesto.numero)}>
                              <span className="link">{envio.presupuesto.numero}</span>
                            </p>
                          }
                          { envio.incidencia_id &&
                            <p className="m-0" onClick={() => handleGoTo('incidencia', envio.incidencia.numero)}>
                              <span className="link">{envio.incidencia.numero}</span>
                            </p>
                          }
                        </td> : null
                      const servicio = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center">
                        <div className="d-flex flex-column">
                          {getServicio(envio.servicio_envio_id)?.nombre}
                          { (envio.transportista_id === 2) ?
                            <a href={"https://www.expressway.es/tracker.php?ref=&alb=" + envio.numero_seguimiento} rel="noreferrer" target="_blank" className="text-info">{envio.numero_seguimiento}</a>
                            : 
                              <>
                                <span className="d-block text-info">{envio.numero_seguimiento}</span>
                                <span title="Estado" onClick={() => handleOpenStatus(envio)}>
                                  <FontAwesomeIcon icon={faLink} size="1x" className="action" color="#215732"></FontAwesomeIcon>
                                </span>
                              </>
                          }
                        </div>
                      </td> : null
                      const precio = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center">
                        {envio.precio}€
                      </td> : null
                      const estado = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center">
                          <div className="w-100 d-flex flex-column">
                            { (envio.estado && envio.estado?.length > 0) ? 
                              <span className={"fw-bold text-uppercase " + getEstado(envio.transportista_id, envio.estado[envio.estado.length -1].codigo)}>
                                {envio.estado[envio.estado.length -1].nombre}
                              </span>
                              : 'DESCONOCIDO'
                            }
                            {envio.cancelado ? <small>{moment(envio.fecha_cancelado).format('DD-MM-YYYY')}</small> : ''}
                          </div>
                        </td> : null
                      const responsable = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center text-uppercase">{envio.created_by?.username}</td> : null
                      const acciones = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center">
                        <ActionsMenu 
                          options={getActions(envio)}
                          onAction={(action) => handleAction(action, envio)}
                        />
                      </td> : null

                      return (
                        <tr key={i}>
                          {codigo}
                          {fecha}
                          {remitente}
                          {destinatario}
                          <td>
                            {pieza.nombre}
                          </td>
                          {seguro}
                          {asegurado}
                          {transportista}
                          {servicio}
                          {precio}
                          {estado}
                          {responsable}
                          {acciones}
                        </tr>
                      )
                    })
                  }
                  

                  if(!piezasRows) {
                    withoutPiezaRow = <tr>
                        <td width="6%">{envio.codigo_transporte}</td>
                        <td>{moment(envio.fecha).format("DD-MM-YYYY")}</td>
                        <td width="15%">
                          { (envio.remitente?.proveedor_id !== null) &&
                              <Link target="_blank" rel="noopener noreferrer" to={"/proveedor/ficha/" + envio.remitente?.proveedor_id} title="Ficha Proveedor" className="text-green text-uppercase">
                                {envio.remitente?.nombre}
                              </Link>
                          }
                          { (envio.remitente?.entity_id !== null) &&
                              <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + envio.remitente?.entity_id} title="Ficha Cliente" className="text-green text-uppercase">
                                {envio.remitente?.nombre}
                              </Link>
                          }
                          { (envio.remitente?.proveedor_id === null && envio.remitente?.entity_id === null) &&
                              <span className="text-uppercase">{envio.remitente?.nombre}</span>
                          }
                        </td> 
                        <td width="15%">
                          { (envio.destinatario?.proveedor_id !== null) &&
                              <Link target="_blank" rel="noopener noreferrer" to={"/proveedor/ficha/" + envio.destinatario?.proveedor_id} title="Ficha Proveedor" className="text-green text-uppercase">
                                {envio.destinatario?.nombre}
                              </Link>
                          }
                          { (envio.destinatario?.entity_id !== null) &&
                              <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + envio.destinatario?.entity_id} title="Ficha Cliente" className="text-green text-uppercase">
                                {envio.destinatario?.nombre}
                              </Link>
                          }
                          { (envio.destinatario?.proveedor_id === null && envio.destinatario?.entity_id === null) &&
                              <span className="text-uppercase">{envio.destinatario?.nombre}</span>
                          }
                        </td>
                        <td>{envio.tipo_pieza_id ? getTipoPieza(envio.tipo_pieza_id)?.nombre : '-'}</td>
                        <td className="text-center">
                          <span className={"fw-bold " + (envio.seguro ? 'text-green' : 'text-danger')}>
                              {envio.seguro ? 'SI' : 'NO'}
                          </span>
                        </td>
                        <td className="text-center">{envio.valor_asegurado ? envio.valor_asegurado + '€' : '-'}</td>
                        <td className="text-center text-uppercase">{getTransportista(envio.transportista_id)?.nombre}</td>
                        <td className="text-center">
                          <div className="d-flex flex-column">
                            {getServicio(envio.servicio_envio_id)?.nombre}
                            <span className="d-block text-info">{envio.codigo_envio}</span>
                            <span title="Estado" onClick={() => handleOpenStatus(envio)}>
                              <FontAwesomeIcon icon={faLink} size="1x" className="action" color="#215732"></FontAwesomeIcon>
                            </span>
                          </div>
                        </td>
                        <td className="text-center">{envio.precio}€</td>
                        <td className="text-center">
                          { (envio.estado && envio.estado?.length > 0) ? 
                            <span className={"fw-bold text-uppercase " + getEstado(envio.transportista_id, envio.estado[envio.estado.length -1].codigo)}>
                              {envio.estado[envio.estado.length -1].nombre}
                            </span>
                            : 'DESCONOCIDO'
                          }
                        </td>
                        <td className="text-center text-uppercase">{envio.created_by?.username}</td>
                        <td width="8%" className="text-center">
                          <div className="actions">
                            <span title="Ver información" style={{cursor: 'pointer'}}>
                              <FontAwesomeIcon icon={faEye} size="1x" className="action" color="#215732" onClick={() => handleOpenInfo(envio)}></FontAwesomeIcon>
                            </span>
                            <span title="Descargar etiqueta PDF" style={{cursor: 'pointer'}}>
                              <FontAwesomeIcon icon={faTag} size="1x" className="action" color="#215732" onClick={() => generateThePdf(envio)}></FontAwesomeIcon>
                            </span>
                            { (envio.transportista_id >= 2 && (envio.numero_seguimiento === "" || envio.numero_seguimiento === null)) &&
                              <span title="Regenerar envío" style={{cursor: 'pointer'}}>
                                <FontAwesomeIcon icon={faTruck} size="1x" className="action" color="#215732" onClick={() => handleOpenRegenerate(envio)}></FontAwesomeIcon>
                              </span>
                            }
                            { (envio.transportista_id !== 1 && !envio.cancelado) &&
                              <span title="Cancelar envío" style={{cursor: 'pointer'}}>
                                <FontAwesomeIcon icon={faTruck} size="1x" className="action" color="#dc3545" onClick={() => handleCancelar(envio)}></FontAwesomeIcon>
                              </span>
                            }
                            { envio.transportista_id !== 1 &&
                              <span title="Incidencia de envío" style={{cursor: 'pointer'}}>
                                <FontAwesomeIcon icon={faTruck} size="1x" className="action" color="#ffc107" onClick={() => handleIncidencia(envio)}></FontAwesomeIcon>
                              </span>
                            }
                          </div>
                        </td>
                    </tr>
                  }

                  return (
                    <tbody key={index}>
                      {piezasRows ? piezasRows : withoutPiezaRow}
                    </tbody>
                  )
                }) 
              }
            </table>
          </div>

          <Pagination 
            className="mt-3" 
            count={pageCount} 
            page={currentPage} 
            onChange={handleChangePage} 
          />

          { openExport && 
            <InfoModal
              state={openExport}
              title="Exportar consumo de envíos"
              content={
                <ExportForm 
                  dateFilter={true}
                  transportistasFilter={true}
                  tipoClienteFilter={false}
                  tipoFacturasFilter={false}
                  responsableFilter={false}
                  onSubmitExport={handleExportConsumo}
                />
                }
              onClose={handleCloseExport}
            ></InfoModal>
          }

          { openNewEnvio && 
            <InfoModal
              state={openNewEnvio}
              title="Nuevo envío"
              width={'lg'}
              content={
                  <EnvioForm 
                    onSubmitEnvio={handleNewEnvio}
                  />
                }
              onClose={handleCloseNewEnvio}
            ></InfoModal>
          }

          { openInfo && 
            <InfoModal
              state={openInfo}
              title={"Información de envío " + currentEnvio.codigo_transporte}
              width={'md'}
              content={
                  <EnvioDetail 
                    envio={currentEnvio}
                  />
                }
              onClose={handleCloseOpenInfo}
            ></InfoModal>
          }

          { openSeguimientoEnvio && 
            <InfoModal
              state={openSeguimientoEnvio}
              title="Añadir información de seguimiento"
              width={'sm'}
              content={
                  <SeguimientoForm 
                    envioId={currentEnvio.id}
                    envio={currentEnvio}
                    onSubmitSeguimiento={handleAddSeguimientoEnvio}
                  />
                }
              onClose={handleCloseAddSeguimiento}
            ></InfoModal>
          }

          { openCancelar && 
            <InfoModal
              state={openCancelar}
              title={"Cancelar envío " + currentEnvio.codigo_transporte}
              width={'md'}
              content={
                  <MessageForm 
                    envioId={currentEnvio.id}
                    onSubmitMessage={handleCancelarEnvio}
                  />
                }
              onClose={handleCloseCancelar}
            ></InfoModal>
          }

          { openIncidencia && 
            <InfoModal
              state={openIncidencia}
              title={"Incidencia de envío " + currentEnvio.codigo_transporte}
              width={'md'}
              content={
                  <MessageForm 
                    envioId={currentEnvio.id}
                    onSubmitMessage={handleIncidenciaEnvio}
                  />
                }
              onClose={handleCloseIncidencia}
            ></InfoModal>
          }

          { openStatus && 
            <InfoModal
              state={openStatus}
              title={"Estado de envío envío " + currentEnvio.codigo_transporte}
              width={'md'}
              content={
                  <EnvioStatusDetail 
                    envio={currentEnvio}
                  />
                }
              onClose={handleCloseStatus}
            ></InfoModal>
          }

          { openRegenerate && 
            <ConfirmModal 
                onConfirmAction={handleConfirmRegenerate} 
                title={'Regenerar envío ' + currentEnvio.codigo_transporte } 
                description={'Estás seguro de regenerar el envío?'}
                state={openRegenerate}>
            </ConfirmModal>
          }
        </div>
      </div>
    )
  }
}