import API from '../../../../../services/api';

export const scrappingURL = async (url) => {
    return API.post('/scrapping', {url: url.url}).then(res => res.data);
}

export const completePartInfoScraper = async (part) => {
    return API.post('/scrappingByRef', {part: part}).then(res => res.data);
}

export const scrappingBlatReferencia = async (tipo, referencia, pagina, id) => {
    return API.post('/blat/referencia', { tipo, referencia, pagina, id }).then((res) => res.data);
};

export const scrappingBlatURL = async (url, id) => {
    return API.post('/blat/url', { url, id }).then((res) => res.data);
};