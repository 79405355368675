import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Dialog, DialogContent, DialogTitle, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { faTimes, faTruck, faRefresh, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrappingForm from "./components/ScrappingForm";
import ScrappingBlatForm from "./components/ScrappingBlatForm";
import ScrappingBlatListForm from "./components/ScrappingBlatListForm";
import PiezaForm from "./components/PiezaForm";
import PiezaFormBlat from "./components/PiezaFormBlat";
import ProveedorSearcher from "./components/ProveedorSearcher";
import ProveedorInfo from "./components/ProveedorInfo";
import PiezasSearcher from "./components/PiezasSearcher";
import PartSearcher from "./components/PartSearcher";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import ProveedorPocoFiableModal from "./components/ProveedorPocoFiableModal";
import { checkAndCreateBloqueoLog, checkIfHasValesProveedor, checkIfIsReliableProveedor } from "../../../../../../../../../shared/helpers/functionalities";
import InfoModal from "../../../../../../../../../shared/components/InfoModal";
import AcceptModal from "../../../../../../../../../shared/components/AcceptModal";

export default function PiezaModal({
    open,
    isEdit,
    esVenta,
    onClose,
    onSubmitPieza,
    onPiezasSelector,
    onProveedorSelector,
    onOpenProveedorForm,
    onOpenServicioEnvio,
    onOpenAvisoCliente
}) {
    const dispatch = useDispatch();
    
    const [tab, setTab] = useState('integracion');
    const presupuesto = useSelector(state => {
        return state.presupuesto.entity
    });
    const currentPieza = useSelector(state => {
        return state.currentPieza
    });
    const currentProveedor = useSelector(state => {
        return state.currentProveedor
    });
    const transportistas = useSelector(state => {
        return state.transportistas
    });
    const serviciosEnvio = useSelector(state => {
        const { servicios_envio } = state;
        return servicios_envio;
    });
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const piezasSchema = yup.object({
        url: yup.string().required(),
        id_referencia_plataforma: yup.string().required(),
        nombre: yup.string().required(),
        referencia_pieza: yup.string().required(),
        precio_original: yup.number().moreThan(0).required(),
        precio_compra: yup.number().moreThan(0).required(),
        precio_venta: yup.number().moreThan(0).required(),
        precio_transporte: yup.number().required(),
        tipo_pieza_id: yup.number().integer().required(),
        tipo_garantia_id: yup.number().integer().required(),
        marca_id: yup.number().integer().required(),
        modelo_id: yup.number().integer().moreThan(0).required(),
    }).required();
    const form = useForm({
        resolver: yupResolver(piezasSchema),
        mode: "onChange",
        defaultValues: {
            id: '',
            url: '',
            id_referencia_plataforma: '',
            nombre: '',
            referencia_pieza: '',
            precio_original: 0,
            precio_compra: 0,
            precio_venta: 0,
            precio_transporte: 0,
            casco: 0,
            tipo_pieza_id: -1,
            tipo_garantia_id: 1,
            marca_id: '',
            modelo_id: 0,
            modelo: null,
            km_motor: 0,
            unidades: 1,
            descuento: 0,
            con_descuento: 1,
            disponible: 1,
            tarifa_valor: 0,
            tarifa_tipo: '',
            integracion: 0,
            pieza_integracion_id: 0,
            integracion_json: '',
            imagenes: null
        }
    });
    const { getValues, reset, watch } = form;
    const [showUnreliableModal, setShowUnreliableModal] = useState(false);
    const [showValesModal, setShowValesModal] = useState(false);
    const [showBlockModal, setShowBlockModal] = useState(false);
    const [currentProv, setCurrentProv] = useState(null);
    const [currentTipoPieza, setCurrentTipoPieza] = useState(null);
    const [blatScrapperFinished, setBlatScrapperFinished] = useState(false);
    const [piezasList, setPiezasList] = useState(null);
    const [blatListScrapperFinished, setBlatListScrapperFinished] = useState(false);
    const [currentReferencia, setCurrentReferencia] = useState(null);

    useEffect(() => {
        if(currentPieza) {
            reset(currentPieza);
        }

    }, [currentPieza, currentProveedor]);

    const handleCloseModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        onClose();
    }

    const handleBack = () => {
        reset(currentPieza);
        dispatch({type: "pieza/clear", payload: null});
        dispatch({type: "proveedor/clear", payload: null});
        setBlatScrapperFinished(true);
        setBlatListScrapperFinished(false);
    }

    const handleReset = () => {
        reset(currentPieza);
        dispatch({type: "pieza/clear", payload: null});
        dispatch({type: "proveedor/clear", payload: null});
        setPiezasList(null);
        setBlatScrapperFinished(false);
        setBlatListScrapperFinished(false);
    }

    const handleChangeTab = (e, tab) => {
        setTab(tab);
        setPiezasList(null);
        dispatch({type: 'pieza/clear', payload: null});
        dispatch({type: "proveedor/clear", payload: null});
    }
   
    const handleSetProveedor = (proveedor) => { 
        dispatch({type: "proveedor/fullfilled", payload: proveedor});
        handleCheckVales(proveedor);
    }

    const handleBlatScrapping = (scrapping, referencia) => {
        setCurrentReferencia(referencia);
        setBlatScrapperFinished(true);
        setPiezasList(scrapping);
    }

    const handleBlatListScrapping = (scrapping, scrappingURL, scrappingId) => {
        if (scrapping) {
            setBlatScrapperFinished(false);
            setBlatListScrapperFinished(true);
        }

        const data = setPiezaFromBlatScrapping(scrapping, scrappingURL, scrappingId);

        if (scrapping.proveedors){
            onProveedorSelector({scrapping: scrapping, url: scrappingURL});
            return;
        } else {
            const isBloqued = checkAndCreateBloqueoLog(scrapping.proveedor, data.tipo_pieza_id);
            if(isBloqued) {
                handleOpenBlockModal(scrapping.proveedor, data.tipo_pieza_id);
                return;
            }

            dispatch({type: "pieza/fullfilled", payload: data});
            dispatch({type: "proveedor/fullfilled", payload: scrapping.proveedor});
            handleCheckVales(scrapping.proveedor);
        } 
    }

    const handleScrapping = (scrapping, scrappingURL) => {
        const data = setPiezaFromScrapping(scrapping, scrappingURL.url);

        // venimos de recambiofacil, con listado de piezas para seleccoinar
        if (scrapping.multiple){
            onPiezasSelector({scrapping: scrapping, url: scrappingURL.url});
            return;
        } 

        if (scrapping.proveedors){
            onProveedorSelector({scrapping: scrapping, url: scrappingURL.url});
            return;
        } else {
            const isBloqued = checkAndCreateBloqueoLog(scrapping.proveedor, data.tipo_pieza_id);
            if(isBloqued) {
                handleOpenBlockModal(scrapping.proveedor, data.tipo_pieza_id);
                return;
            }

            dispatch({type: "pieza/fullfilled", payload: data});
            dispatch({type: "proveedor/fullfilled", payload: scrapping.proveedor});
            handleCheckVales(scrapping.proveedor);
        }        
    }

    const setPiezaFromScrapping = (scrapping, scrappingURL) => {
        return {
            ...scrapping,
            url: scrappingURL,
            precio_original: scrapping.precio_original ? parseFloat(scrapping.precio_original) : scrapping.precio_original,
            precio_compra: scrapping.precio_original ? parseFloat(scrapping.precio_original) : scrapping.precio_original,
            precio_transporte: scrapping.precio_envio ? parseFloat(scrapping.precio_envio) : 0,
            casco: 0,
            marca_id: scrapping.marca_id ? scrapping.marca_id : '',
            modelo_id: scrapping.modelo_id ? scrapping.modelo_id : '',
            tipo_pieza_id: scrapping.tipo_pieza_id ? scrapping.tipo_pieza_id : -1,
            km_motor: scrapping.kilometraje ? scrapping.kilometraje : '',
            observaciones: scrapping.observaciones ? scrapping.observaciones : '',
            con_descuento: true,
            unidades: 1
        }
    }

    const setPiezaFromBlatScrapping = (scrapping, scrappingURL) => {
        return {
            url: scrappingURL,
            nombre: scrapping.name,
            precio_original: scrapping.precio_original,
            id_referencia_plataforma: scrapping.id_referencia_plataforma,
            referencia_pieza: scrapping.referencia_pieza,
            marca_id: scrapping.marca_id,
            modelo: scrapping.modelo,
            modelo_id: scrapping.modelo_id,
            tipo_pieza_id: scrapping.tipo_pieza_id,
            km_motor: scrapping.km_motor,
            imagenes: scrapping.imagenes,
            con_descuento: true,
            precio_transporte: 0,
            casco: 0,
            unidades: 1,
        }
    }

    const handleAddPieza = (pieza) => {
        dispatch({type: "pieza/fullfilled", payload: pieza});
        dispatch({type: "proveedor/fullfilled", payload: pieza.proveedor});
        handleCheckVales(pieza.proveedor);
    }

    const handleSubmitPieza = async (data) => {
        data.proveedor_id = currentProveedor.id;
        data.servicio_envio = currentPieza.servicio_envio;
        dispatch({type: "pieza/fullfilled", payload: data});

        if(tab === "manual") {
            if(!checkIfIsReliableProveedor(currentProveedor)) {
                setShowUnreliableModal(true);
            } else {
                handleCheckVales(currentProveedor);
                onSubmitPieza(data);
                setShowUnreliableModal(false);
            }
        } else {
            handleCheckVales(currentProveedor);
            onSubmitPieza(data);
        }
    };

    const handleSubmitPiezaUnreiableProveedor = (confirm) => {
        if(confirm) {
            onSubmitPieza(currentPieza);
        }

        setShowUnreliableModal(false);
    }

    const handleSelectedPartFromScrapingByRef = (part) => {
        if (part.marketPlace === 'Integración') {
            handleAddPieza(part);
            return;
        }

        const data = setPiezaFromScrapingByRef(part);

        if (data.proveedors) {
            onProveedorSelector({scrapping: data, url: data.url});
            return;
        }

        dispatch({type: "pieza/fullfilled", payload: data});
        dispatch({type: "proveedor/fullfilled", payload: data.proveedor});
        handleCheckVales(data.proveedor);
    }

    const getTransportista = (transportistaId) => { return transportistas.find(t => t.id === transportistaId) }
    const getServicio = (servicioId) => { return serviciosEnvio.find(s => s.id === servicioId) }

    const setPiezaFromScrapingByRef = (part) => {
        const price = part.precio_original ? parseFloat(part.precio_original) : part.precio_original;

        return {
            ...part,
            precio_original: price,
            precio_compra: price,
            precio_transporte: part.precio_envio ? parseFloat(part.precio_envio) : 0,
            casco: 0,
            marca_id: part.marca_id ? part.marca_id : '',
            modelo_id: part.modelo_id ? part.modelo_id : '',
            tipo_pieza_id: part.tipo_pieza_id ? part.tipo_pieza_id : -1,
            km_motor: part.km_motor ? part.km_motor : 0,
            observaciones: '',
            con_descuento: true,
            unidades: 1,
        };
    }

    const handleChangeEnvioProveedor = (precio) => {
        dispatch({type: "pieza/servicio_envio_precio", payload: precio});
    }

    const handleCheckVales = (proveedor) => {
        if(checkIfHasValesProveedor(proveedor)) {
            setCurrentProv(proveedor);
            setShowValesModal(true);
        } else handleCloseValesProveedor();
    }
    const handleCloseValesProveedor = () => { 
        setCurrentProv(null);
        setShowValesModal(false); 
    }

    const handleOpenBlockModal  = (proveedor, tipoPieza = null) => {
        setCurrentProv(proveedor);
        setCurrentTipoPieza(tipoPieza);
        setShowBlockModal(true);
    }
    const handleCloseBlockModal = () => { handleCloseModal(); }
    
    // <Tab label="Buscar por Referencia" value='ScrapByRef' />

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCloseModal}
                fullWidth={true}
                maxWidth={'lg'}
                aria-labelledby="pieza-form-title"
                aria-describedby="pieza-form-description">
                <DialogTitle id="pieza-form-title">
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <p className="text-uppercase fw-bold">{isEdit ? 'Editar' : 'Nueva'} Pieza</p>

                        <div className="d-flex align-items-center gap-4">
                            <div className="d-flex gap-1">
                                {(tab === "scrapping-blat" && currentPieza && !isEdit) && (
                                    <button
                                        className="btn btn-primary d-flex align-items-center justify-content-center"
                                        onClick={handleBack}
                                        style={{ width: "42px", height: "42px", position: "relative" }}
                                        type="button"
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft} size="1x" color="#white" />
                                    </button>
                                )}
                                {((blatScrapperFinished || currentPieza) && !isEdit) && (
                                    <button
                                        className="btn btn-primary d-flex align-items-center justify-content-center"
                                        onClick={handleReset}
                                        style={{ width: "42px", height: "42px", position: "relative" }}
                                        type="button"
                                    >
                                        <FontAwesomeIcon icon={faRefresh} size="1x" color="#white" />
                                    </button>
                                )}
                            </div>
                            <FontAwesomeIcon
                                className="action"
                                color="#215732"
                                icon={faTimes}
                                onClick={handleCloseModal}
                                size="1x"
                            />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                { (!isEdit) ?
                    <div className="w-100">
                        { (!currentPieza && !piezasList && !currentProveedor) &&
                            <TabContext value={tab}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChangeTab} aria-label="Pieza">
                                    <Tab label="Manual" value="manual" />
                                    <Tab label="Scrapping (Propio)" value="scrapping" />
                                    <Tab label="Scrapping (Blat)" value="scrapping-blat" />
                                    <Tab label="Integración" value="integracion" />
                                </TabList>
                                </Box>
                                <TabPanel value="manual">
                                    <div className="w-100">
                                        <ProveedorSearcher 
                                            onNew={onOpenProveedorForm}
                                            onProveedorSelected={handleSetProveedor}
                                            onCancel={handleCloseModal}
                                        />
                                    </div>
                                </TabPanel>

                                <TabPanel value="scrapping">
                                    <div className="w-100">
                                        <ScrappingForm onScrapping={handleScrapping}/>
                                    </div>
                                </TabPanel>
                                
                                <TabPanel value="scrapping-blat">
                                    <div className="w-100">
                                        <ScrappingBlatForm onScrapping={handleBlatScrapping}/>
                                    </div>
                                </TabPanel>

                                <TabPanel value="ScrapByRef">
                                    <div className="w-100">
                                        <PartSearcher
                                            onSelectedPart={handleSelectedPartFromScrapingByRef}
                                            onBlockedPart={handleOpenBlockModal}
                                        />
                                    </div>
                                </TabPanel>

                                <TabPanel value="integracion">
                                    <div className="w-100">
                                        <PiezasSearcher 
                                            onResultSelected={handleAddPieza} 
                                            onCancel={handleCloseModal}
                                            onBlockedPart={handleOpenBlockModal}
                                        />
                                    </div>
                                </TabPanel>
                            </TabContext>
                        }
                        
                        <div className="w-100">
                            { blatScrapperFinished && 
                                <ScrappingBlatListForm
                                    piezasList={piezasList}
                                    onScrapping={handleBlatListScrapping}
                                    currentReferencia={currentReferencia}
                                />
                            }
                            { (currentProveedor) && 
                                <div className="w-100">
                                    <div className="w-100 d-flex align-items-center mb-3">
                                        <ProveedorInfo 
                                            proveedor={currentProveedor} 
                                            onEdit={onOpenProveedorForm}
                                        />

                                        <div className="flex-grow-1 d-flex align-items-center justify-content-end me-3">
                                            { (currentPieza?.servicio_envio) ?
                                                <>
                                                    <FontAwesomeIcon icon={faTruck} color="#215732" className="me-3"></FontAwesomeIcon>

                                                    { currentPieza.servicio_envio.transportista_id !== 1 && <p className="text-uppercase me-2">{getTransportista(currentPieza.servicio_envio.transportista_id)?.nombre}</p>}
                                                    <p className="text-uppercase">{getServicio(currentPieza.servicio_envio.servicio_envio_id)?.nombre}</p>
                                                    <span className="mx-2">-</span>
                                                    <p className="text-uppercase">{currentPieza.servicio_envio?.precio_transporte}€</p>
                                                </>

                                                : <p className="fw-bold text-danger">No hay envio seleccionado</p>
                                            }
                                        </div>

                                        <button 
                                            type="button" 
                                            className="btn btn-success" 
                                            onClick={() => onOpenServicioEnvio(getValues())}
                                            disabled={
                                                watch('tipo_pieza_id') === -1 || 
                                                watch('tipo_pieza_id') === null || 
                                                watch('tipo_pieza_id') === undefined
                                            }>
                                                Envío
                                        </button>
                                    </div>

                                    {(blatListScrapperFinished)
                                        ? <PiezaFormBlat 
                                            esVenta={esVenta}
                                            form={form}
                                            isEditing={false}
                                            onBlockedPart={handleOpenBlockModal}
                                            onCancel={handleCloseModal}
                                            onChangePrecioEnvioProveedor={handleChangeEnvioProveedor}
                                            onOpenAvisoCliente={onOpenAvisoCliente}
                                            onSubmitPieza={handleSubmitPieza}
                                            pieza={currentPieza}
                                            proveedor={currentProveedor}
                                            tarifaClienteId={presupuesto?.cliente?.tarifa_cliente_id}
                                        />
                                        : <PiezaForm  
                                            form={form}
                                            pieza={currentPieza}
                                            proveedor={currentProveedor}
                                            tarifaClienteId={presupuesto?.cliente?.tarifa_cliente_id}
                                            isEditing={false}
                                            fromScrapping={tab === 'scrapping' || tab === 'ScrapByRef' ? true : false}
                                            onSubmitPieza={handleSubmitPieza}
                                            onCancel={handleCloseModal}
                                            onChangePrecioEnvioProveedor={handleChangeEnvioProveedor}
                                            onBlockedPart={handleOpenBlockModal}
                                            onOpenAvisoCliente={onOpenAvisoCliente}
                                        ></PiezaForm>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    :
                        <div className="w-100">
                            <div className="w-100 d-flex align-items-center justify-content-between mb-3">
                                <ProveedorInfo 
                                    proveedor={currentProveedor} 
                                    onEdit={onOpenProveedorForm}
                                />

                                <div className="flex-grow-1 d-flex align-items-center justify-content-end me-3">
                                    { (currentPieza?.servicio_envio) ?
                                        <>
                                            <FontAwesomeIcon icon={faTruck} color="#215732" className="me-3"></FontAwesomeIcon>

                                            { currentPieza.servicio_envio.transportista_id !== 1 && <p className="text-uppercase me-2">{getTransportista(currentPieza.servicio_envio.transportista_id)?.nombre}</p>}
                                            <p className="text-uppercase">{getServicio(currentPieza.servicio_envio.servicio_envio_id)?.nombre}</p>
                                            <span className="mx-2">-</span>
                                            <p className="text-uppercase">{currentPieza.servicio_envio?.precio_transporte}€</p>
                                        </>

                                        : <p className="fw-bold text-danger">No hay envio seleccionado</p>
                                    }
                                </div>

                                <button 
                                    type="button" 
                                    className="btn btn-success" 
                                    onClick={() => onOpenServicioEnvio()}
                                    disabled={
                                        watch('tipo_pieza_id') === -1 || 
                                        watch('tipo_pieza_id') === null || 
                                        watch('tipo_pieza_id') === undefined
                                    }>
                                        Envío
                                </button>
                            </div>

                            <PiezaForm 
                                form={form}
                                pieza={currentPieza}
                                proveedor={currentProveedor}
                                tarifaClienteId={presupuesto?.cliente?.tarifa_cliente_id}
                                isEditing={true}
                                fromScrapping={null}
                                esVenta={esVenta}
                                onSubmitPieza={handleSubmitPieza}
                                onCancel={handleCloseModal}
                                onChangePrecioEnvioProveedor={handleChangeEnvioProveedor}
                                onBlockedPart={handleOpenBlockModal}
                                onOpenAvisoCliente={onOpenAvisoCliente}
                            ></PiezaForm>
                        </div> 
                }
                </DialogContent>
            </Dialog>

            { (showUnreliableModal) &&
                <ProveedorPocoFiableModal
                    pieza={currentPieza}
                    onConfirmAction={handleSubmitPiezaUnreiableProveedor}
                    state={showUnreliableModal}>
                </ProveedorPocoFiableModal>
            }

            { (showValesModal) && 
                <InfoModal
                    state={showValesModal}
                    title={'Aviso: ' + currentProv.nombre + ' tiene vales de descuento disponibles'}
                    content={
                        <p>{currentProv.nombre} tiene un vale de {currentProv?.vales?.find(v => v.active)?.total - currentProv?.vales?.find(v => v.active)?.gastado}€ disponible</p>
                    }
                    width={'sm'}
                    onClose={handleCloseValesProveedor}
                ></InfoModal>
            }

            { (showBlockModal) && 
                <AcceptModal
                    state={showBlockModal}
                    title={"TIPO DE PIEZA BLOQUEADA"}
                    content={
                        <p>
                            {currentProv?.nombre} tiene bloqueada el tipo de pieza &nbsp;
                            <b>{tipoPiezas.find(tp => tp.id === currentTipoPieza)?.nombre}</b>
                            , ratio incidencia alto.
                        </p>
                    }
                    width={'sm'}
                    onClose={handleCloseBlockModal}
                ></AcceptModal>
            }
        </div>
    )
}