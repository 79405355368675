import { useState, useEffect } from "react";
import React from "react";
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import {enableDisableUser} from '../shared/services/users';
import Header from '../shared/components/Header'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../../../redux/users";
import { setErrorMessage } from "../shared/helpers/functionalities";
import Loading from "../shared/components/Loading";
import ActionsMenu from "../shared/components/ActionsMenu";
import { getActions } from "./actions";

export default function Users() {
  const dispatch = useDispatch();
  const { page } = useParams();
  const currPage = parseInt(page);
  let navigate = useNavigate();
  const [currentPage, setPage] = useState(currPage);
  const roles = useSelector(state => {
    const { roles } = state;
    return roles;
  });
  const users = useSelector(state => {
    return state.users.entities
  });
  const usersStatus = useSelector(state => {
    return state.users.status
  });
  const searcher = useSelector(state => {
    return state.search
  })

  useEffect(() => {
    dispatch(fetchUsers(currPage, null));
  }, [searcher]);

  const handleSearch = (search, searchData) => {
    setPage(1);
    navigate(`/users/${1}`);
    dispatch({type: "search/set", payload: searchData});     
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigate(`/users/${newPage}`);
    var query = (searcher && searcher?.search && searcher?.search.length > 0 ? 'search=' + searcher?.search : null);
    dispatch(fetchUsers(newPage, query));
  }

  const getPaginationCount = () => {
    var totalPages = users.total_items < 15 ? 1 : Math.ceil(users.total_items / 15);
    return parseInt(totalPages);
  }

  const handleAction = (action, user) => {
    if(action === 'edit') {
      navigate(`/user/${user.id}`);
    } else  if(action === 'enable') {
      handleActivateDesactivateUser(user.id, true)
    } else  if(action === 'disable') {
      handleActivateDesactivateUser(user.id, false)
    } 
  }

  const handleNew = () => {}

  const getRoleName = (rolId) => roles.find(r => r.id === rolId)?.nombre;

  const handleActivateDesactivateUser = async (userId, isEnable) => {
    dispatch({type: 'loading/set', payload: true});
    const userResult = await enableDisableUser({user_id: userId, state: isEnable})
    .catch(function (error) {
      toast.error(setErrorMessage(error))
      dispatch({type: 'loading/set', payload: false});
    });

    if(userResult && userResult.success && userResult.data) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(userResult.message)
      window.location.reload();
    } 
  }

  if(usersStatus.loading === 'pending' || usersStatus.loading === 'idle') {
    return ( <Loading /> );
  }

  if(usersStatus.loading === 'succeed' || usersStatus.loading === 'rejected') {
    const pageCount = getPaginationCount();

    return (
      <div className="content pt-3">
        <Header 
          viewSearch={true}
          onNew={handleNew} 
          onSearch={handleSearch} />

        <div className="w-100 d-flex flex-column align-items-end content-table">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>username</th>
                  <th>email</th>
                  <th className="text-center">telefono</th>
                  <th className="text-center">activo</th>
                  <th className="text-center">rol</th>
                  <th className="text-center"></th>
                </tr>
              </thead>

              <tbody>
                { users.users?.map((user) => {
                  return (
                    <tr key={user.id}>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td className="text-center">{user.telefono}</td>
                      <td className="text-center">{user.active ? <FontAwesomeIcon icon={faCheck} color="#00986C" /> : <FontAwesomeIcon icon={faTimes} color="#DC3545" />}</td>
                      <td className="text-center text-uppercase">{roles.length > 0 ? getRoleName(user.rol_id) : ''}</td>
                      <td width={'5%'} className="text-center">
                        <ActionsMenu 
                            options={getActions(user)}
                            onAction={(action) => handleAction(action, user)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Pagination 
            className="mt-3" 
            count={pageCount} 
            page={currentPage} 
            onChange={handleChangePage} 
          />
        </div>
      </div>
    )
  }
}