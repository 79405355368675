import React from "react";
import { Link } from "react-router-dom";
import { faBookDead, faExclamationCircle, faFileAlt, faFileExcel, faListCheck, faPlus, faShop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Header.scss';
import Searcher from './components/Searcher';

export default function Header({
    viewSearch,
    detalleTab,
    onNew,
    onSearch, 
    onExport, 
    onCompare,
    onReport,
    onAvisos,
    onEcommerce,
    onPresupuesto,
    onActualizarPedidos
}) {
    let pathname = window.location.pathname === '/' ? 'home' : window.location.pathname.replace('/', '');
    const user = JSON.parse(localStorage.getItem('user'));
    const handleNew = () => onNew();
    const handleChange = (search, searchData) => onSearch(search, searchData);
    const handleExport = (item = null) => onExport(item);
    const handleCompare = () => onCompare();
    const handleReport = () => onReport();
    const handleAvisos = () => onAvisos();
    const pageName = pathname.includes("/") ? pathname.split("/")[0] : pathname;
    const itemPageName = pageName === 'proveedores' ? 'proveedor' : pageName === 'comerciales' ? 'taller' : pageName.substring(0, pageName.length - 1);

    return (
        <div className="w-100 d-flex flex-column align-items-start">
            <div className="w-100 d-flex align-items-center justify-content-between">
                <h1 className="page-name">{pageName?.replace('-', ' ')}</h1>

                <div className="flex-grow-1 d-flex align-items-center justify-content-end mb-2">
                { pageName !== 'settings' &&
                    <div className="d-flex align-items-center align-self-end">
                        { pageName === 'incidencias' &&
                            <div className="w-100 d-flex align-items-center">
                                { (user.rol_id === 1 || user.rol_id === 5 || user.rol_id === 4) &&
                                    <button className="btn btn-add" onClick={() => handleExport('perdidas')}>
                                        <span className="add">
                                            <FontAwesomeIcon icon={faFileExcel} size="1x" className="action" color="#215732" /> 
                                        </span>
                                        Pérdidas
                                    </button>
                                }

                                <button className="btn btn-add mx-3"  onClick={() => handleExport('culpa')}>
                                    <span className="add">
                                        <FontAwesomeIcon icon={faFileExcel} size="1x" className="action" color="#215732" /> 
                                    </span>
                                    Culpa Recomotor 
                                </button>

                                <button className="btn btn-add"  onClick={() => handleAvisos()}>
                                    <span className="add">
                                        <FontAwesomeIcon icon={faExclamationCircle} size="1x" className="action" color="#215732" /> 
                                    </span>
                                    Avisos 
                                </button>
                            </div>
                        }
                        { pageName === 'facturas' &&
                            <>
                                <button className="btn btn-add me-3"  onClick={() => handleCompare()}>
                                    <span className="add">
                                        <FontAwesomeIcon icon={faFileExcel} size="1x" className="action" color="#215732" /> 
                                    </span>
                                    Comparar
                                </button>

                                <button className="btn btn-add me-3"  onClick={() => handleExport()}>
                                    <span className="add">
                                        <FontAwesomeIcon icon={faFileExcel} size="1x" className="action" color="#215732" /> 
                                    </span>
                                    Exportar
                                </button>

                                <button className="btn btn-add"  onClick={() => handleReport()}>
                                    <span className="add">
                                        <FontAwesomeIcon icon={faFileAlt} size="1x" className="action" color="#215732" /> 
                                    </span>
                                    Informe
                                </button>
                            </>
                        }
                        { (pageName === 'clientes' && (user.rol_id === 1 || user.rol_id === 4)) &&
                            <button className="btn btn-add me-3"  onClick={() => handleExport('extraccion')}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faFileExcel} size="1x" className="action" color="#215732" /> 
                                </span>
                                Extracción
                            </button>
                        }
                        { (pageName === 'clientes' && user.rol_id === 1) &&
                            <>
                                <button className="btn btn-add me-3"  onClick={() => handleExport('ventas')}>
                                    <span className="add">
                                        <FontAwesomeIcon icon={faFileExcel} size="1x" className="action" color="#215732" /> 
                                    </span>
                                    Ventas
                                </button>

                                <button className="btn btn-add me-3"  onClick={() => handleExport('margen')}>
                                    <span className="add">
                                        <FontAwesomeIcon icon={faFileExcel} size="1x" className="action" color="#215732" /> 
                                    </span>
                                    Ventas con margen
                                </button>

                                <button className="btn btn-add me-3"  onClick={() => handleExport('feedback')}>
                                    <span className="add">
                                        <FontAwesomeIcon icon={faFileExcel} size="1x" className="action" color="#215732" /> 
                                    </span>
                                    Feedback
                                </button>
                            </>
                        }
                        { (pageName === 'proveedores' && (user.rol_id === 1 || user.rol_id === 6)) &&
                            <button className="btn btn-add me-3"  onClick={() => handleExport()}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faFileAlt} size="1x" className="action" color="#215732" /> 
                                </span>
                                Consumo
                            </button>
                        }
                        { (pageName === 'comerciales') &&
                            <button className="btn btn-add me-3"  onClick={() => handleExport()}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faFileAlt} size="1x" className="action" color="#215732" /> 
                                </span>
                                Conversión clientes
                            </button>
                        }
                        
                        { (pageName === 'envios' && user.rol_id === 1) &&
                            <button className="btn btn-add me-3"  onClick={() => handleExport()}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faFileAlt} size="1x" className="action" color="#215732" /> 
                                </span>
                                Consumo
                            </button>
                        }

                        { (pageName === 'presupuestos' && user.rol_id === 1) &&
                            <button className="btn btn-add me-3"  onClick={() => handleExport()}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faFileAlt} size="1x" className="action" color="#215732" /> 
                                </span>
                                Presupuestos Clientes
                            </button>
                        }

                        { (pageName === 'presupuestos') &&
                            <button className="btn btn-add"  onClick={() => onEcommerce()}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faShop} size="1x" className="action" color="#215732" /> 
                                </span>
                                Ecommerce
                            </button>
                        }

                        { (pageName === 'ecommerce') &&
                            <button className="btn btn-add"  onClick={() => onPresupuesto()}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faBookDead} size="1x" className="action" color="#215732" /> 
                                </span>
                                Presupuestos
                            </button>
                        }

                        { (pageName === 'autorizaciones-credito') &&
                            <button className="btn btn-add"  onClick={() => onActualizarPedidos()}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faListCheck} size="1x" className="action" color="#215732" /> 
                                </span>
                                Comprobar Presupuestos
                            </button>
                        }

                        { ( pageName !== 'ventas' && 
                            pageName !== 'presupuestos' &&
                            pageName !== 'facturas' && 
                            pageName !== 'facturas-vinaros' && 
                            pageName !== 'devoluciones' && 
                            pageName !== 'incidencias' && 
                            pageName !== 'envios' &&
                            pageName !== 'solicitudes' &&
                            pageName !== 'desguaces-offline' &&
                            pageName !== 'reconstruidos' &&
                            pageName !== 'finanzas' &&
                            pageName !== 'comerciales' &&
                            pageName !== 'portugal' &&
                            pageName !== 'clientes' &&
                            pageName !== 'proveedores' &&
                            pageName !== 'ecommerce' && 
                            pageName !== 'notas-cargo' && 
                            pageName !== 'avisos-stock' &&
                            pageName !== 'autorizaciones-credito'
                            )
                            &&  
                                <Link to={"/" + itemPageName + "/0"} className="ms-3">
                                    <button className="btn btn-add">
                                        <span className="add">
                                            <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                                        </span>
                                        Nuevo
                                    </button>

                                </Link>
                        }
                        { (pageName === 'presupuestos' && user.rol_id === 2)
                            &&  
                                <Link to={"/" + itemPageName + "/0"} className="ms-3">
                                    <button className="btn btn-add">
                                        <span className="add">
                                            <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                                        </span>
                                        Nuevo
                                    </button>

                                </Link>
                        }

                        { ( pageName === 'envios' || 
                            pageName === 'avisos-stock' || 
                            pageName === 'comerciales' || 
                            pageName === 'portugal' || 
                            pageName === 'proveedores' || 
                            pageName === 'solicitudes' ||
                            pageName === 'clientes') 
                            &&
                            <button className="btn btn-add" onClick={handleNew}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                                </span>
                                 Nuevo
                            </button>                              
                        }
                    </div>
                }
                </div>
            </div>
            
            { (viewSearch) &&
                <Searcher 
                    detalleTab={detalleTab}
                    pageName={pageName}
                    onChange={handleChange}/>
            }
        </div>
    );
}

/*
//const handleCheck = () => onCheck();
{ (pageName === 'comerciales') &&
    <div className="ms-5">
        <button className="btn btn-primary d-flex align-items-center" onClick={() => handleCheck()}>
            Comprobar teléfonos <FontAwesomeIcon icon={faFileExcel} className="ms-2" />
        </button>
    </div>
}

// CLIENTES
<button className="btn btn-add me-3"  onClick={() => handleCompare()}>
    <span className="add">
        <FontAwesomeIcon icon={faFileExcel} size="1x" className="action" color="#215732" /> 
    </span>
    Comparar
</button>
*/